<template>
    <div class="ptb_100">
        <section id="articles" class="section articles-area d-flex">
            <div class="container-fluid">
                <div class="row mb-4">
                    <div class="col px-4" v-if="lifecycles.length > 0">
                        <span class="lifecycle mr-2 mr-xl-4" @click="setType('all')" v-if="lang==='en'" :class="[selectedLifecycle === 'all' ? 'lifecycle-active' : '']">All</span>
                        <span class="lifecycle mr-2 mr-xl-4" @click="setType('all')" v-if="lang==='hun'" :class="[selectedLifecycle === 'all' ? 'lifecycle-active' : '']">Összes</span>

                        <span class="lifecycle mr-2 mr-xl-4" @click="setType('venture_story')" v-if="lang==='en'" :class="[selectedLifecycle === 'venture_story' ? 'lifecycle-active' : '']">Venture stories</span>
                        <span class="lifecycle mr-2 mr-xl-4" @click="setType('venture_story')" v-if="lang==='hun'" :class="[selectedLifecycle === 'venture_story' ? 'lifecycle-active' : '']">Befektetői sztorik</span>
                        <span class="lifecycle mr-2 mr-xl-4" @click="setType('blog')" :class="[selectedLifecycle === 'blog' ? 'lifecycle-active' : '']">Blog</span>

                    </div>
                </div>
                <div class="row mb-4" v-if="!articles || articles.length === 0">
                    <div class="col-xl-6" v-for="index in 8" :key="index">
                        <div class=" ph-item">
                            <div class="ph-col-12">
                                <div class="ph-row my-4 py-4">
                                    <div class="ph-col-4 "></div>
                                    <div class="ph-col-10 big my-4"></div>
                                    <div class="ph-col-8 "></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-4" v-if="articles && articles.length > 0">
                    <div v-for="(a, index) in articles" :key="a._id" class="col-xl-6 mb-3">
                        <card :article="a" :color="bgClasses[index % bgClasses.length]" :showImage="getImageVisibility(index)" />
                    </div>
                </div>
            </div>
        </section>
    </div>

</template>

<script>
    import { mapState } from 'vuex';
    import Card from './Card';

    export default {
        data() {
            return {
                baseUrl: process.env.VUE_APP_STRAPI_URI,
                width: window.innerWidth,
                bgClasses: ['bg-green', 'bg-light-blue', 'bg-dark-blue', 'bg-classic-blue'],
                showImages: [true, true, false, false],
                selectedLifecycle: 'all',
                types: ['venture_story', 'blog'],
            };
        },
        components: {
            'card': Card
        },
        computed: { 
            ...mapState(['lang', 'articles', 'lifecycles', 'areas']),
        },
        methods: {
            getImageVisibility(index) {
                if (this.width < 1200 && index % 2 === 0) {
                    return true;
                } else if (this.width >=1200 && this.showImages[index % this.showImages.length]) {
                    return true;
                } else {
                    return false;
                }
            },
            setType(type) {
                if (type === 'all') {
                    this.selectedLifecycle = 'all';
                    this.$store.dispatch('getArticlesGraph', {
                        limit: 0,
                        sort: "pubDate:desc"
                    });
                    this.addParamsToLocation({});

                } else {
                    this.selectedLifecycle = type;
                    this.$store.dispatch('getArticlesByType', type);
                    this.addParamsToLocation({type: type});
                }
            },
            addParamsToLocation(params) {
                history.pushState(
                    {},
                    null,
                    this.$route.path +
                    '?' +
                    Object.keys(params)
                        .map(key => {
                        return (
                            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                        )
                        })
                        .join('&')
                )
            },
            checkParams() {
                if (this.$route.query.type) {
                    for (var i = 0; i < this.types.length; i++) {
                        if (this.$route.query.type === this.types[i]) {
                            this.setType(this.types[i]);
                        }
                    }

                } else if (this.$route.query.area) {
                    for (var j = 0; j < this.areas.length; j++) {
                        if (this.$route.query.area === this.areas[j].en) {
                            this.$store.dispatch('getArticlesByArea', this.areas[j]._id);
                            this.selectedLifecycle = '';
                            this.addParamsToLocation({area: this.areas[j].en});
                        }
                    }
                
                } else {
                    this.setType('all');
                }
            }
        },
        watch: {
            '$route.query.area': function() {
                this.checkParams()
            }
        },
        mounted() {
            this.$store.commit('setArticle', '');

            window.addEventListener('resize', () => {         
                this.width = window.innerWidth       
            });

            this.checkParams();            
        }
    }
</script>